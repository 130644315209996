import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  async getToken() {
    const id = jwtDefaultConfig.jwtid
    const secret = jwtDefaultConfig.jwtsecret
    const username = jwtDefaultConfig.jwtusername
    const password = jwtDefaultConfig.jwtpassword
    const url = jwtDefaultConfig.jwtEndpoint
    try {
      const response = await axios.post(
        `${url}/${id}/${secret}`, {},
        {
          auth: {
            username,
            password,
          },
        },
      )
      return response.data.jwt
    } catch (error) {
      console.error('error jwt', error)
      return null
    }
  }
}
