export default {
  // Endpoints
  jwtEndpoint: process.env.VUE_APP_URL_JWT,
  jwtid: process.env.VUE_APP_JWT_ID,
  jwtsecret: process.env.VUE_APP_JWT_SECRET,
  jwtusername: process.env.VUE_APP_JWT_USERNAME,
  jwtpassword: process.env.VUE_APP_JWT_PASSWORD,

  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
